import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import Image from 'gatsby-image';
import Top from "../components/blocks/top"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath, postsPerPage },
}) => {
  const posts = data.allWpPost.nodes;
  const totalPages = Math.ceil(data.allWpPost.pageInfo.totalCount / postsPerPage);
  const currentPage = parseInt(data.allWpPost.pageInfo.currentPage)
  if (!posts.length) {
    return (
      <Layout page={{ uri: '/blogg/' }}>
        <Seo />
      </Layout>
    );
  }

  return (
    <Layout page={{ uri: '/blogg/' }}>
      <Seo title="Blogg" />
      <Top item={{ text: '', small: true }} />
      <section className={`latest-blogposts`} id="main">
        <div className="container-fluid">
          <div className="row blog-row">
            {posts.map((item) => {
              return (
                <article
                  className="col-12 blog-wrap"
                  itemScope
                  itemType="http://schema.org/Article"
                  key={item.uri}
                >
                  <div className="row align-items-center g-5">
                    <div className="col-md-6 align-self-start image-wrap">
                      {item?.featuredImage?.node && (
                        <Image
                          fluid={
                            item?.featuredImage?.node?.localFile
                              ?.childImageSharp?.fluid
                          }
                          loading={`eager`}
                          fadeIn={false}
                          alt={item?.featuredImage?.node?.altText}
                        />
                      )}
                    </div>
                    <div className="col-md-6">
                      {item?.title && <h4>{item.title}</h4>}
                      {item?.excerpt && (
                        <div className="text">{parse(item.excerpt || '')}</div>
                      )}
                      <Link
                        className="btn"
                        to={item.uri}
                        aria-label={item.title}
                      >
                        Läs mer
                      </Link>
                    </div>
                  </div>
                </article>
              );
            })}
            <div className="col-12">
              {totalPages > 1 && (
                <nav aria-label="Page navigation">
                  <ul className="pagination justify-content-center">
                    {previousPagePath && (
                      <li className="page-item previous">
                        <Link className="page-link" to={previousPagePath}>
                          Föregående
                        </Link>
                      </li>
                    )}
                    {!previousPagePath && (
                      <li className="page-item disabled">
                        <button
                          className="page-link"
                          href="#"
                          tabindex="-1"
                          aria-disabled="true"
                        >
                          Föregående
                        </button>
                      </li>
                    )}
                    {[...Array(totalPages).keys()].map((item, i) => {
                      const pageNumber = parseInt(i + 1);
                      const pageLink = `/blogg/${
                        pageNumber > 1 ? pageNumber : ''
                      }`;
                      return (
                        <li
                          className={`page-item ${
                            pageNumber === currentPage ? 'active' : ''
                          }`}
                          aria-current={
                            pageNumber === currentPage ? 'page' : false
                          }
                        >
                          <Link className="page-link" to={pageLink}>
                            {pageNumber}
                          </Link>
                        </li>
                      );
                    })}
                    {nextPagePath && (
                      <li className="page-item next">
                        <Link className="page-link" to={nextPagePath}>
                          Nästa
                        </Link>
                      </li>
                    )}
                    {!nextPagePath && (
                      <li className="page-item disabled">
                        <button
                          className="page-link"
                          href="#"
                          tabindex="-1"
                          aria-disabled="true"
                        >
                          Nästa
                        </button>
                      </li>
                    )}
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1400, toFormat: JPG, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        uri
        title
        excerpt
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        totalCount
        currentPage
      }
    }
  }
`;
